.task-inventory-card-form .task-inventories-list {
  display: flex;
  flex-direction: column;
  align-items: flex-end; }
  .task-inventory-card-form .task-inventories-list .task-inventory-container {
    margin-bottom: 8px; }
    .task-inventory-card-form .task-inventories-list .task-inventory-container:last-child {
      margin-bottom: 0; }

.task-inventory-card-form .task-inventory {
  background-color: white;
  padding: 0 0 4px 0;
  border-radius: 8px;
  border: 1px #e1e1e1 solid;
  margin-right: 0; }
  .task-inventory-card-form .task-inventory .task-inventory-header,
  .task-inventory-card-form .task-inventory .task-inventory-title,
  .task-inventory-card-form .task-inventory .task-inventory-quantity,
  .task-inventory-card-form .task-inventory .task-inventory-data {
    padding: 0 12px; }
  .task-inventory-card-form .task-inventory .task-inventory-header {
    padding-top: 12px; }
  .task-inventory-card-form .task-inventory .task-inventory-data {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 40px;
    border-top: 1px #e1e1e1 solid;
    padding-top: 8px; }
    .task-inventory-card-form .task-inventory .task-inventory-data .task-inventory-data-item {
      width: 50%;
      box-sizing: border-box;
      padding-right: 5px; }
    .task-inventory-card-form .task-inventory .task-inventory-data .task-inventory-form-item {
      width: 30%;
      margin-top: 8px; }

.task-inventory-card-form .task-inventory-card-form-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px; }
  .task-inventory-card-form .task-inventory-card-form-buttons .task-inventory-card-form-button {
    width: 110px;
    height: 35px;
    line-height: 35px; }

.task-inventory-card-form .floating-input {
  border: none !important;
  margin-bottom: 0; }
