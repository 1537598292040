.page-header {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: solid 1px #e1e1e1; }
  .page-header .page-header-item {
    padding: 10px 20px; }
    .page-header .page-header-item .page-header-item-link {
      color: #000; }
    .page-header .page-header-item.active {
      border-bottom: 4px solid #4a90e2; }
      .page-header .page-header-item.active .page-header-item-link {
        color: #4a90e2; }

.form-save {
  min-height: 63px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10; }
  .form-save .save-info {
    color: #737373;
    margin-top: 7px; }
    .form-save .save-info svg {
      font-size: 14px;
      margin-right: 5px;
      color: #000; }
  .form-save .save-body {
    display: flex;
    justify-content: space-between;
    padding: 15px 25px;
    background-color: #fff;
    border-bottom: 1px solid #e1e1e1;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05); }
  .form-save .save-body-revert {
    color: #4a90e2;
    text-decoration: underline;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer; }
    .form-save .save-body-revert:hover {
      color: #4a90e2;
      text-decoration: underline; }
  .form-save .save-button {
    background-color: #4a90e2;
    color: #fff; }

.side-menu {
  height: 100vh;
  width: 200px;
  font-size: 14px;
  font-weight: normal; }
  .side-menu .logo {
    border-radius: 50px;
    width: 48px;
    height: 48px;
    margin: 0 16px 15px;
    position: relative;
    z-index: 1000; }
  .side-menu .logout {
    background-color: #494949;
    height: 45px;
    width: 100%;
    padding-top: 10px;
    margin-top: 5px;
    cursor: pointer;
    text-align: center; }
  .side-menu .menu-icon {
    margin-right: 15px; }
  .side-menu .ant-menu {
    height: 100%;
    width: 100%;
    padding-top: 32px; }
  .side-menu .menu-header {
    color: #fff;
    background-color: #4a4a4a;
    height: 36px;
    width: 100%;
    padding: 12px 0 0;
    align-items: center; }
    .side-menu .menu-header .close-icon,
    .side-menu .menu-header .open-icon {
      float: right;
      cursor: pointer;
      margin-right: 7px; }
    .side-menu .menu-header .open-icon {
      margin-right: 18px; }
  .side-menu .menu-footer {
    color: #fff;
    background-color: #252525;
    padding: 0; }
    .side-menu .menu-footer .user-name {
      font-weight: 500;
      margin-left: 15px; }
    .side-menu .menu-footer .user-email {
      font-size: 12px;
      margin-left: 15px; }
    .side-menu .menu-footer .open-icon {
      cursor: pointer;
      margin-left: 15px; }
  .side-menu .item-title {
    color: #fff; }
  .side-menu .ant-menu.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #ff4422; }
  .side-menu .ant-menu.ant-menu-dark .ant-menu-item-active {
    background-color: #828282; }
  .side-menu .ant-menu-dark {
    background-color: #252525; }

.side-menu-collapsed {
  width: 43px; }
  .side-menu-collapsed .menu-header {
    background-color: black; }
  .side-menu-collapsed .menu-footer {
    padding-bottom: 15px; }
  .side-menu-collapsed .menu-icon {
    margin-left: -8px;
    color: #fff; }

.side-menu-container .menu-sider {
  width: 0 !important;
  min-width: 0 !important; }

.side-menu-container .menu-sider-collapsed {
  flex: 0 0 43px !important; }
