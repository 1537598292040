.waypoint-times-container .waypoint-time-column-container {
  color: #505050;
  font-size: 12px; }
  .waypoint-times-container .waypoint-time-column-container .waypoint-time-content {
    font-weight: 500; }

.waypoint-times-container .ant-row {
  margin-bottom: 15px; }

.waypoint-times-container .ant-row:last-child {
  margin-bottom: 0; }
