.customer-contact-container .customer-title {
  color: #505050;
  font-size: 12px; }

.customer-contact-container .customer-details .customer-name {
  color: #3d8deb;
  font-size: 15px;
  font-weight: 500; }

.customer-contact-container .customer-details .customer-phone {
  margin-top: 5px;
  font-size: 13px;
  color: #000; }
  .customer-contact-container .customer-details .customer-phone span {
    margin-left: 7px; }

.customer-contact-container .customer-details .ant-rate {
  color: #000; }
