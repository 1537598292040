.task-inventory {
  min-width: 230px;
  padding-right: 30px;
  margin-right: 30px;
  font-size: 12px;
  color: #000;
  border-right: 1px #e1e1e1 solid; }
  .task-inventory .task-inventory-tooltip {
    border-bottom: 1px #000 dashed; }
  .task-inventory .task-inventory-header,
  .task-inventory .task-inventory-icon {
    color: #888888; }
  .task-inventory .task-inventory-header {
    display: flex;
    align-items: center; }
    .task-inventory .task-inventory-header .bringg-label {
      margin-left: 10px; }
    .task-inventory .task-inventory-header .orange {
      background-color: #ff8715; }
    .task-inventory .task-inventory-header .green {
      background-color: #05a528; }
  .task-inventory .task-inventory-content {
    display: flex;
    justify-content: space-between; }
  .task-inventory .task-inventory-icon {
    color: #494949;
    margin-right: 12px;
    width: 15px;
    height: 12px; }
  .task-inventory .task-inventory-title {
    font-weight: 500;
    margin-top: 12px; }
  .task-inventory .task-inventory-quantity {
    margin-bottom: 12px; }
    .task-inventory .task-inventory-quantity .task-inventory-rejected-quantity {
      color: #ff4422;
      font-weight: 500;
      margin-left: 15px; }
  .task-inventory .task-inventory-notes {
    display: flex; }
  .task-inventory .task-inventory-edit-link {
    margin-top: 12px;
    color: #3d8deb;
    font-size: 11px; }
    .task-inventory .task-inventory-edit-link:hover {
      cursor: pointer;
      text-decoration: underline; }
  .task-inventory .task-inventory-delete {
    align-self: baseline;
    margin-left: auto;
    color: #494949;
    font-size: 14px;
    cursor: pointer; }
    .task-inventory .task-inventory-delete:hover {
      color: #ff4422; }
  .task-inventory .task-inventory-edit-quantity {
    width: 27%;
    align-self: center;
    margin-top: 8px;
    margin-right: 30px; }

.task-inventory-edit-modal {
  font-family: Rubik; }
  .task-inventory-edit-modal .ant-modal-content,
  .task-inventory-edit-modal .ant-modal-header {
    background-color: #f6f6f6; }
  .task-inventory-edit-modal .ant-modal-content {
    box-shadow: none;
    border-color: #e1e1e1;
    border-radius: 8px; }
  .task-inventory-edit-modal .ant-modal-header {
    border-radius: 8px 8px 0 0;
    border-bottom: none; }
  .task-inventory-edit-modal .ant-modal-title {
    font-size: 24px;
    color: #000;
    line-height: 32px; }
  .task-inventory-edit-modal .ant-modal-body {
    padding: 20px 24px; }
  .task-inventory-edit-modal .ant-modal-footer {
    border-top: none; }
