.waypoint-card-form .waypoint-card-form-section {
  position: relative;
  background-color: white;
  border-radius: 8px;
  padding: 28px 16px 20px 16px;
  margin-bottom: 40px; }
  .waypoint-card-form .waypoint-card-form-section:last-child {
    margin-bottom: 0; }
  .waypoint-card-form .waypoint-card-form-section:before {
    content: attr(section-title);
    position: absolute;
    top: -24px;
    left: 0;
    font-size: 14px;
    font-weight: 500;
    color: #929292; }
  .waypoint-card-form .waypoint-card-form-section .waypoint-card-form-input {
    margin-bottom: 30px; }
    .waypoint-card-form .waypoint-card-form-section .waypoint-card-form-input:last-child {
      margin-bottom: 0; }
    .waypoint-card-form .waypoint-card-form-section .waypoint-card-form-input .input {
      height: 18px;
      margin-bottom: 0; }

.waypoint-card-form .waypoint-card-form-inline-section {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 28px; }
  .waypoint-card-form .waypoint-card-form-inline-section .waypoint-card-form-input {
    margin-bottom: 0; }

.waypoint-card-form .waypoint-card-form-inline-section-three {
  grid-template-columns: repeat(3, auto); }

.waypoint-card-form .waypoint-card-form-asap-container {
  display: flex;
  column-gap: 16px;
  align-items: center; }

.waypoint-card-form .waypoint-card-form-date-picker-container {
  position: relative; }
  .waypoint-card-form .waypoint-card-form-date-picker-container:before {
    content: attr(input-placeholder);
    position: absolute;
    top: -14px;
    left: 0;
    color: #898989;
    font-size: 11px; }

.waypoint-card-form .waypoint-card-form-picker {
  width: 100%; }
  .waypoint-card-form .waypoint-card-form-picker .ant-calendar-picker-input,
  .waypoint-card-form .waypoint-card-form-picker .ant-time-picker-input {
    border-radius: 0;
    border: none;
    border-bottom: 1px #e1e1e1 solid;
    color: #000;
    height: 24px;
    font-size: 14px;
    padding: 0;
    margin-bottom: 0; }
    .waypoint-card-form .waypoint-card-form-picker .ant-calendar-picker-input:focus,
    .waypoint-card-form .waypoint-card-form-picker .ant-time-picker-input:focus {
      box-shadow: none; }
    .waypoint-card-form .waypoint-card-form-picker .ant-calendar-picker-input:hover,
    .waypoint-card-form .waypoint-card-form-picker .ant-time-picker-input:hover {
      border-bottom-color: #3d8deb; }
  .waypoint-card-form .waypoint-card-form-picker .ant-calendar-picker-icon,
  .waypoint-card-form .waypoint-card-form-picker .ant-calendar-picker-clear,
  .waypoint-card-form .waypoint-card-form-picker .ant-time-picker-icon,
  .waypoint-card-form .waypoint-card-form-picker .ant-time-picker-clear {
    right: 0; }

.waypoint-card-form .waypoint-card-form-buttons {
  display: flex;
  justify-content: space-between; }
  .waypoint-card-form .waypoint-card-form-buttons .waypoint-card-form-button:first-child {
    margin-right: 8px; }

.waypoint-card-form-confirm-delete .anticon {
  color: red; }
