.floating-input-container {
  position: relative;
  color: #000; }
  .floating-input-container .input-icon {
    position: absolute;
    right: 15px; }
  .floating-input-container.error .input-error {
    color: #ff4422;
    font-size: 11px;
    position: absolute;
    left: 0;
    bottom: -21px; }
    .floating-input-container.error .input-error svg {
      margin-right: 6px; }
  .floating-input-container.error:after, .floating-input-container.error:before {
    background-color: #ff4422; }
  .floating-input-container.float .placeholder {
    transform: translateY(-75%) scale(0.75);
    top: 0; }
  .floating-input-container.disabled:before {
    opacity: 0; }
  .floating-input-container:focus-within:after, .floating-input-container:hover:not(.disabled):after {
    opacity: 1; }
  .floating-input-container:focus-within .placeholder {
    color: #3d8deb; }
  .floating-input-container:focus-within .floating-input {
    color: #000; }
  .floating-input-container:after, .floating-input-container:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    transition-property: opacity;
    transition-duration: 0.3s; }
  .floating-input-container:before {
    opacity: 1;
    background-color: #e1e1e1; }
  .floating-input-container:after {
    opacity: 0;
    background-color: #3d8deb; }
  .floating-input-container .placeholder {
    position: absolute;
    pointer-events: none;
    transition-property: transform, top, color;
    transition-duration: 0.3s;
    transform-origin: top left;
    color: #828282;
    top: 50%;
    left: 2px;
    transform: translateY(-50%); }
  .floating-input-container .floating-input {
    outline: none;
    padding: 2px;
    height: 100%;
    width: 100%;
    background: none;
    border: none;
    color: #494949;
    /* Chrome, Safari, Edge, Opera */
    /* Firefox */ }
    .floating-input-container .floating-input::-webkit-outer-spin-button, .floating-input-container .floating-input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; }
    .floating-input-container .floating-input[type='number'] {
      -moz-appearance: textfield; }
